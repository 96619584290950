import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
//import AppTheme from './components/appTheme';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/i18n/Language';
import deLocale from 'date-fns/locale/de';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {Backdrop} from "@mui/material";
import {Hearts} from "react-loader-spinner";
const App = React.lazy(() => import ("./App")) ;
const AppTheme = React.lazy(() => import ("./components/appTheme"));
// if (performance.navigation.type == performance.navigation.TYPE_RELOAD ||
// 	 performance.navigation.type == performance.navigation.TYPE_NAVIGATE
// 	 || performance.navigation.type == performance.navigation.TYPE_BACK_FORWARD) {

//   }else{
// 	localStorage.setItem('userToken','null')
//   }
ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div><Backdrop  open={true}><Hearts
			height="100"
			width="100"
			color='#ff5e62'
			ariaLabel='loading'
		/></Backdrop></div>}>
		<AppTheme>

			<Provider store={store}>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
				<I18nextProvider i18n={i18n}>
					<App />
					</I18nextProvider>
				</LocalizationProvider>
			</Provider>

		</AppTheme>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
